import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoomMtg } from '@zoomus/websdk';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { DataService } from '../data.service';


ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})

export class ZoomComponent implements OnInit {
  public meetConfig: any;
  public classObj: any;
  public signature: any;
  alertmsg: any;
  resourcetype: any[] = [];
  zoomid: any
  zoompassword: any;
  dialog: any;
  pedid: any;
  name: any;
  email: any;
  host: any;
  jwt: any;
  slot_id: any
  errormsg: any

  constructor(private DataService: DataService, private router: Router, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    // this.SetConfg(97095295999)
    this.pedid = JSON.parse(localStorage.getItem('pedid'))
    this.list()
  }

  list() {
    this.DataService.meeting_details(this.pedid).pipe(first()).subscribe(list => {
      console.log(list)
      if (list.success) {
        this.resourcetype = list.Meetingdetails
        this.zoomid = this.resourcetype[0].zoom_id
        this.zoompassword = this.resourcetype[0].zoom_password
        this.name = this.resourcetype[0].first_name
        this.host = this.resourcetype[0].host
        this.email = this.resourcetype[0].email
        this.zoompassword = this.resourcetype[0].zoom_password
        this.slot_id = this.resourcetype[0].Slot_id
        this.SetConfg(this.zoomid)
      }
      else {
        this.errormsg = list.message
      }
    })
  }


  SetConfg(val) {
    this.meetConfig = {
      apiKey: environment.apiKey,
      apiSecret: environment.apiSecret,
      meetingNumber: val,
      userName: this.name,
      userEmail: this.email,
      passWord: this.zoompassword, // if required
      role: this.host ,// 1 for host; 0 for attendee
    };

    this.signature = ZoomMtg.generateSignature({
      meetingNumber: this.meetConfig.meetingNumber,
      apiKey: this.meetConfig.apiKey,
      apiSecret: this.meetConfig.apiSecret,
      role: this.meetConfig.role,
      success: res => {
        console.log(res.result)
      },
      error: res => {
        // console.log(res)
      }
    });



    ZoomMtg.init({
      debug: true, //optional
      leaveUrl: 'http://dev.elite.dap-health.com/image/slot-book-thankyou.png', //required
      showMeetingHeader: true, //option
      disableInvite: false, //optional
      disableCallOut: false, //optional
      disableRecord: false, //optional
      disableJoinAudio: true, //optional
      audioPanelAlwaysOpen: true, //optional
      showPureSharingContent: false, //optional
      isSupportAV: true, //optional,
      isSupportChat: true, //optional,
      isSupportQA: true, //optional,
      isSupportPolling: true, //optional
      isSupportBreakout: true, //optional
      isSupportCC: true, //optional,
      screenShare: true, //optional,
      rwcBackup: '', //optional,
      videoDrag: true, //optional,
      sharingMode: 'both', //optional,
      videoHeader: true, //optional,
      isLockBottom: true, // optional,
      isSupportNonverbal: true, // optional,
      isShowJoiningErrorDialog: true, // optional
      success: res => {
        document.getElementById('zmmtg-root').style.display = 'block'
        ZoomMtg.join({
          meetingNumber: this.meetConfig.meetingNumber,
          userName: this.meetConfig.userName,
          signature: this.signature,
          apiKey: this.meetConfig.apiKey,
          userEmail: this.meetConfig.userEmail,
          passWord: this.meetConfig.passWord,
          success: res => {
            this.DataService.join_meeting(this.slot_id).pipe(first()).subscribe(list => {
              if (list.success) {
                this.alertmsg = "Meeting Joined Succesfully"
              }
              else {
                this.alertmsg = list.message
              }
            })
          },
          error: res => {
            console.log(res)
          }
        })
      }
    })
  }
}
