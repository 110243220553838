const apiKey = "3oIOz-qlRNedIRZ4qIYdkg"
const apiSecret = "pl2AE1wtq9twLKotUfIOOAHt1MS9fXYvdIb1"
const API = `http://dev.api.elite.dap-health.com/`
// const API = `http://localhost:9498/api/`

export const environment = {
  apiUrl: API,
  apiKey: apiKey,
  apiSecret: apiSecret,
  production: true
};
