import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../user';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css']
})
@Injectable({
  providedIn: 'root'
})

export class WebinarComponent implements OnInit {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<User>;
  pedid: string;
  jwt: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  ngOnInit(): void {
    let pedid = this.activatedRoute.snapshot.params.pedid;
    let jwt = this.activatedRoute.snapshot.params.jwt;
    this.activatedRoute.paramMap.subscribe(params => {
      this.pedid = params.get('pedid');
      this.jwt = params.get('jwt');
      localStorage.setItem('currentUser', JSON.stringify(this.jwt));
      this.currentUserSubject.next(this.jwt);
      localStorage.setItem('pedid', this.pedid);
    });
    this.router.navigate(['/']);
  }
}
