import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './gurad';
import { WebinarComponent } from './webinar/webinar.component';
import { ZoomComponent } from './zoom/zoom.component';

const routes: Routes = [
  { path: 'zoom/:pedid/:jwt', component: WebinarComponent },
  { path: '', component: ZoomComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
