import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { User } from './user';
const API = environment.apiUrl

@Injectable({
    providedIn: 'root'
})

export class DataService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    role: number;
    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }


    meeting_details(pedid: any) {
        return this.http.post<any>(API + `user/meeting/` + pedid, {});
    }

    join_meeting(slot_id: any) {
        console.log(slot_id)
        return this.http.put<any>(API + `user/meetingjoined`, { slot_id });
    }

}